import apothecaryTattooShopbyJosh from './apothecaryTattooShopbyJosh.webp';
import './App.css';

function App() {
  return (
    <div className="App">
          <header className="App-header">
            <h2> Apothecary Tattoo</h2>
            <p>578 Marsh St, San Luis Obispo, CA 93401</p>
            <p>(805) 752-1035</p>
            <p>jstockstell@live.com</p>
            <p>Call or email with questions or appointment requests</p>
            <h3>Sorry our website is under construction! Please check back soon!</h3>
            <img src={apothecaryTattooShopbyJosh} alt="logo" />
          </header>
        </div>
  );
}

export default App;
